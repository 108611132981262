import { trackTemplate } from '../../../utils/tracking';

/**
 * Tab click
 * @param string text
 */
export function tabClick(text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Clicks',
      action: 'Tabs',
      label: text,
      value: '0',
    },
  });
}
