import isEmpty from 'lodash.isempty';
import key from 'weak-key';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// types
import type { TabsPayload } from '../content-elements-payload';

// components
import TabItems from './tab-items';
import { ContentElement } from '../ContentElement';
import { Title } from '../Title/Title';
import { BUTTON_TYPES, Button } from 'components/Button/Button';

// utils
import { TitleFormats } from '../Title/title.types';
import { useIsMobile } from 'components/App/SizeProvider';
import { useSectionId } from 'utils/hooks/use-section-id';

export function TabsContainer({
  tabs,
  title,
  subtitle,
  anchor,
  contentIndex,
  titleInAnchor,
  catalogName,
  isSectionReference,
}: Readonly<
  Omit<TabsPayload, 'titleInAnchor'> & { titleInAnchor: boolean; isSectionReference: boolean }
>) {
  const sectionId = useSectionId(title, anchor, titleInAnchor, contentIndex);
  const isMobile = useIsMobile();

  if (isEmpty(tabs)) return null;

  const renderAccordion = () => {
    const accordionItems = tabs.map((tab) => {
      const sections = tab.content.map((element, index) => (
        <ContentElement
          // coding guideline exception: we spread all server props to the component
          {...element}
          key={key(element)}
          contentIndex={index}
          contentLength={tab.content.length}
          isSectionReference={isSectionReference}
          isChild
        />
      ));

      return (
        <AccordionItem className="c-accordion__item" key={key(tab)}>
          <AccordionItemHeading>
            <AccordionItemButton className="c-accordion__title grid-x">
              <Title Format={TitleFormats.h6} title={tab.buttonText} className="auto cell" />
              <Button type={BUTTON_TYPES.PLAIN} className="large-1" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="c-accordion__body">
            <div className="c-accordion__content">{sections}</div>
          </AccordionItemPanel>
        </AccordionItem>
      );
    });

    return (
      <Accordion allowMultipleExpanded allowZeroExpanded className="c-accordion">
        {accordionItems}
      </Accordion>
    );
  };

  return (
    <div className="grid-container" id={sectionId}>
      {isMobile && title && (
        <Title title={title} subtitle={subtitle} Format={TitleFormats.h2} formatClassName="h3" />
      )}
      {isMobile ? (
        renderAccordion()
      ) : (
        <TabItems
          tabs={tabs}
          title={title}
          subtitle={subtitle}
          sectionId={sectionId}
          catalogName={catalogName}
          isSectionReference={isSectionReference}
        />
      )}
    </div>
  );
}
