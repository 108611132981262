import key from 'weak-key';

// types
import type { TabData } from '../content-elements-payload';

// components
import { ContentElement } from '../ContentElement';
import { InlineEdit } from '../../ContentCreator/InlineEdit';

// utils
import { decodingContent } from 'utils/decodingContent';

interface TabItemProps {
  tab: TabData;
  index: number;
  isSectionReference: boolean;
}

export default function TabItem({ tab, index, isSectionReference }: Readonly<TabItemProps>) {
  return (
    <>
      <h3 className="tab-nav__title--invisible">{decodingContent(tab.buttonText)}</h3>
      <InlineEdit previewId={isSectionReference ? undefined : `#${index}`}>
        <InlineEdit previewId={isSectionReference ? undefined : `#${tab.catalogName}`}>
          {tab.content.map((element, subIndex) => (
            <div
              key={key(element)}
              data-preview-id={isSectionReference ? undefined : `#${subIndex}`}
            >
              <ContentElement
                // coding guideline exception: we spread all server props to the component
                {...element}
                key={key(element)}
                contentIndex={subIndex}
                contentLength={tab.content.length}
                isSectionReference={isSectionReference}
                isChild
              />
            </div>
          ))}
        </InlineEdit>
      </InlineEdit>
    </>
  );
}
