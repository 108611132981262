import { useContext, useState } from 'react';
import Tabs from 'react-responsive-tabs';

// types
import type { TabData } from '../content-elements-payload';

// components
import TabItem from './tab-item';
import { Title } from '../Title/Title';
import { UPDATE_ACCORDION_STATE, EditingContext } from '../../ContentCreator/EditingContext';
import { Translation } from '../../Translation/Translation';
import { InlineEdit } from '../../ContentCreator/InlineEdit';

// utils
import { tabClick } from './tracking-actions';
import { TitleFormats } from '../Title/title.types';
import { decodingContent } from 'utils/decodingContent';
import { useTracking } from 'utils/hooks/useTracking';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { useTracking as useTracking2 } from 'utils/tracking/track';

interface TabItemsProps {
  title: string;
  tabs: TabData[];
  subtitle?: string;
  sectionId?: string;
  isSectionReference?: boolean;
  catalogName?: string;
}

export default function TabItems({
  tabs,
  title,
  subtitle = '',
  sectionId,
  isSectionReference = false,
  catalogName,
}: Readonly<TabItemsProps>) {
  const { state: editingState, dispatch } = useContext(EditingContext);
  const [currentOpenTab, setCurrentOpenTab] = useState(editingState[tabs[0].previewId]);
  const track = useTracking();
  const { trackClick } = useTracking2();
  const isPreview = useIsPreview();

  // minimum 2 tabs
  if (tabs.length < 2) {
    return null;
  }

  const onChange = (index) => {
    track.trackEvent(tabClick(tabs[index].buttonText));
    trackClick({
      click_intent: 'more_info',
      click_element: 'tab',
      click_text: tabs[index].buttonText,
    });

    if (isPreview) {
      setCurrentOpenTab(index);

      dispatch({
        type: UPDATE_ACCORDION_STATE,
        accordionIndex: index,
        previewId: tabs[index].previewId,
      });
    }
  };

  const getTabs = () =>
    tabs.map((tab, index) => {
      return {
        key: index,
        tabClassName: 'tab-nav__tabs', // Optional
        panelClassName: 'tab-nav__panel', // Optional
        title: <div className="tab-nav__title">{decodingContent(tab.buttonText)}</div>,
        content: <TabItem tab={tab} index={index} isSectionReference={isSectionReference} />,
      };
    });

  return (
    <div className="tab-nav" id={sectionId}>
      <Title
        className="tab-nav__headline"
        title={title}
        subtitle={subtitle}
        Format={TitleFormats.h2}
        titlePreviewId="#st_title"
        subtitlePreviewId="#st_subtitle"
        isSectionReference={isSectionReference}
        formatClassName="h3"
      />
      <InlineEdit previewId={catalogName && !isSectionReference ? `#${catalogName}` : undefined}>
        <Tabs
          items={getTabs()}
          unmountOnExit={false}
          transform={false}
          showMoreLabel={<Translation id="web20_more_label" />}
          onChange={onChange}
          selectedTabKey={currentOpenTab}
        />
      </InlineEdit>
    </div>
  );
}
